<template>
  <div id="app">
    <div class="videoBox">
      <div class="topBox">
        <div class="leftBox"></div>
        <div class="centerBox">
          <div class="showResult">
            <ul>
              <li v-for="(key,id,number) in result" :class="result[id].bg">{{key.number}}</li>
            </ul>
          </div>
          <div class="tip">当前期：{{this.n}}</div>
        </div>
        <div class="rightBox">
          <div class="soundButton"></div>
          <div class="information">
            <div>下期：{{this.n+1}}</div>
            <div>开奖：{{nextDate}}</div>
          </div>
        </div>
      </div>
      <div class="timeCountDown" v-show="!opening">{{countDowm}}</div>
      <div class="opening" v-show="opening">开奖中...</div>
      <div class="middleBox">
        <ul id="init">
          <li v-for="(key,id) in obj" :key="id" :style="randomPosition()" :class="obj[id].bg">{{key.number}}</li>
        </ul>
      </div>
      <div class="button" v-if="isButton" v-on:click="this.start"></div>
    </div>
  </div>

</template>

<script>
  export default {
    data() {
      return {
        obj: [
          {id: 1, number: 1, bg: 'blueBG'},
          {id: 2, number: 2, bg: 'blueBG'},
          {id: 3, number: 3, bg: 'blueBG'},
          {id: 4, number: 4, bg: 'blueBG'},
          {id: 5, number: 5, bg: 'blueBG'},
          {id: 6, number: 6, bg: 'blueBG'},
          {id: 7, number: 7, bg: 'blueBG'},
          {id: 8, number: 8, bg: 'blueBG'},
          {id: 9, number: 9, bg: 'blueBG'},
          {id: 10, number: 10, bg: 'blueBG'},
          {id: 11, number: 11, bg: 'blueBG'},
          {id: 12, number: 12, bg: 'blueBG'},
          {id: 13, number: 13, bg: 'blueBG'},
          {id: 14, number: 14, bg: 'blueBG'},
          {id: 15, number: 15, bg: 'blueBG'},
          {id: 16, number: 16, bg: 'blueBG'},
          {id: 17, number: 17, bg: 'blueBG'},
          {id: 18, number: 18, bg: 'blueBG'},
          {id: 19, number: 19, bg: 'redBG'},
          {id: 20, number: 20, bg: 'redBG'},
        ],
        result: [], // 存放开奖结果
        list: [], // 存放小球的随机位置
        isButton: true,
        n: 0, // 期数
        time: 20,  // 初始倒计时，如果需要修改倒计时的数值，需要在 init 方法里面修改 time, 并且需要修改 first 方法的 setInteral 的时间
        opening: false
      }
    },
    computed: {
      countDowm: function () {
        let h, m, s, restTime
        s = this.time
        if (s < 60) {
          h = 0
          m = 0
          s = s
        }
        if (s >= 60 && s <= 3600) {
          h = 0
          m = parseInt(s / 60)
          s = parseInt(s % 60)
        }

        if (s < 10) {
          s = '0' + s
        }
        if (m < 10) {
          m = '0' + m
        }
        if (h < 10) {
          h = '0' + h
        }

        return restTime = h + ':' + m + ':' + s
      },
      nextDate: function () {
        let now, show
        now = new Date()
        let h = now.getHours()
        h = h < 10 ? '0' + h : h
        let m = now.getMinutes()
        if (m < 10) {
          m = '0' + m
        } else if (m > 60) {
          m = '0' + m - 60
        } else {
          m = m
        }
        let s = now.getSeconds() + this.time
        if (s < 10) {
          s = '0' + s
        } else if (s > 60) {
          s = '0' + s - 60
          m + 1
        } else {
          s = s
        }
        show = h + ':' + m + ':' + s
        return show
      }
    },
    mounted() {
      this.first()
    },
    methods: {
      randomPosition() {
        const x = 70 + Math.round(Math.random() * 178)
        return {
          left: x + 'px',
          top: 223 + 'px'
        }
      },
      allRandomPosition() {
        const ul = document.getElementById('init')
        const lis = ul.childNodes
        for (let i = 0; i < 100; i++) {
          this.list.push([this.positionX(), this.positionY()])
        }
        const id = setInterval(() => {
          for (let i = 0; i < 20; i++) {
            const index = Math.floor((Math.random() * 100))
            lis[i].style.cssText = `left:${this.list[index][0]}px;top:${this.list[index][1]}px`
          }
        }, 30)
        setInterval(() => {
          window.clearInterval(id)
          ul.childNodes.forEach(item => item.style.top = 230 + 'px')
          this.opening = false
        }, 3000)
      },
      positionX() {
        const x = 30 + Math.round(Math.random() * 260)
        return x
      },
      positionY() {
        const y = Math.round(Math.random() * 223)
        return y
      },
      reset() {
        this.result = []
        this.randomPosition()
      },
      start() {
        this.opening = true
        this.isButton = false
        this.allRandomPosition()
        setTimeout(this.run, 3000)
        this.n += 1
      },
      run() {
        this.result = []
        const arrayCopy = this.obj
        let i = 0, delNum, delArr
        for (i = 0; i < 8; i++) {
          delNum = Math.floor(Math.random() * arrayCopy.length)
          delArr = arrayCopy.splice(delNum, 1)
          this.result = this.result.concat(delArr)
        }
        return this.result
      },
      init() {
        this.obj = [
          {id: 1, number: 1, bg: 'blueBG'},
          {id: 2, number: 2, bg: 'blueBG'},
          {id: 3, number: 3, bg: 'blueBG'},
          {id: 4, number: 4, bg: 'blueBG'},
          {id: 5, number: 5, bg: 'blueBG'},
          {id: 6, number: 6, bg: 'blueBG'},
          {id: 7, number: 7, bg: 'blueBG'},
          {id: 8, number: 8, bg: 'blueBG'},
          {id: 9, number: 9, bg: 'blueBG'},
          {id: 10, number: 10, bg: 'blueBG'},
          {id: 11, number: 11, bg: 'blueBG'},
          {id: 12, number: 12, bg: 'blueBG'},
          {id: 13, number: 13, bg: 'blueBG'},
          {id: 14, number: 14, bg: 'blueBG'},
          {id: 15, number: 15, bg: 'blueBG'},
          {id: 16, number: 16, bg: 'blueBG'},
          {id: 17, number: 17, bg: 'blueBG'},
          {id: 18, number: 18, bg: 'blueBG'},
          {id: 19, number: 19, bg: 'redBG'},
          {id: 20, number: 20, bg: 'redBG'},
        ],
          this.result = [],
          this.time = 20 // 如果修改倒计时时间，这里也需要修改，需要跟 time 数值一致
      },
      first() {
        setInterval(() => {
          this.time--
        }, 1000)
        setInterval(() => {
          this.start()
          this.init()
        }, 20000)  // 如果修改倒计时时间，这里也需要修改，需要跟 time 数值一致
      },


    }
  }
</script>


<style>
  @import "./css/gunQiu.css";

</style>
